import { interpolate } from 'remotion';

export interface Animation {
  type: 'fade' | 'slide' | 'scale' | 'none';
  duration: number; // duration in frames
  distance?: number; // distance for slide animation
  direction?: 'left' | 'right' | 'up' | 'down'; // direction for slide animation
}

export const getAnimationStyles = (frame: number, animations?: Animation[], isOut: boolean = false) => {
  if (!animations || animations.length === 0) {
    return {};
  }

  const combinedStyles: React.CSSProperties = {};
  let translateX = 0;
  let translateY = 0;
  let scaleX = 1;
  let scaleY = 1;
  let opacity = 1;
  let hasTranslate = false;
  let hasScale = false;
  let hasOpacity = false;

  // Sort animations by duration in descending order
  const sortedAnimations = animations.sort((a, b) => b.duration - a.duration);

  sortedAnimations.forEach(animation => {
    if (animation.type === 'none') {
      return;
    }

    const progress = interpolate(
      frame,
      [0, animation.duration],
      [0, 1],
      {
        extrapolateLeft: 'clamp',
        extrapolateRight: 'clamp',
      }
    );

    const adjustedProgress = isOut ? 1 - progress : progress;

    switch (animation.type) {
      case 'fade':
        hasOpacity = true;
        opacity *= adjustedProgress;
        break;
      case 'slide': {
        const distance = animation.distance || 100;
        hasTranslate = true;
        switch (animation.direction) {
          case 'left':
            translateX += interpolate(adjustedProgress, [0, 1], [distance, 0]);
            break;
          case 'right':
            translateX += interpolate(adjustedProgress, [0, 1], [-distance, 0]);
            break;
          case 'up':
            translateY += interpolate(adjustedProgress, [0, 1], [distance, 0]);
            break;
          case 'down':
            translateY += interpolate(adjustedProgress, [0, 1], [-distance, 0]);
            break;
          default:
            translateX += interpolate(adjustedProgress, [0, 1], [distance, 0]);
        }
        break;
      }
      case 'scale':
        hasScale = true;
        scaleX *= adjustedProgress;
        scaleY *= adjustedProgress;
        break;
      default:
        break;
    }
  });

  if (hasTranslate) {
    const translate = `translate(${translateX}%, ${translateY}%)`;
    combinedStyles.transform = combinedStyles.transform
      ? `${combinedStyles.transform} ${translate}`
      : translate;
  }

  if (hasScale) {
    const scale = `scale(${scaleX}, ${scaleY})`;
    combinedStyles.transform = combinedStyles.transform
      ? `${combinedStyles.transform} ${scale}`
      : scale;
  }

  if (hasOpacity) {
    combinedStyles.opacity = opacity;
  }

  return combinedStyles;
};