import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import RemotionPlayer from './components/RemotionPlayer';
import './fonts.css';
import ComingSoon from './components/ComingSoon';

const App: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<ComingSoon />} />
            <Route path="/player" element={<RemotionPlayer />} />
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
};

export default App;
