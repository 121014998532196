import React, { useEffect, useState } from 'react';
import TextComponent from './MediaComponents/TextComponent';
import ImageComponent from './MediaComponents/ImageComponent';
import VideoComponent from './MediaComponents/VideoComponent';
import { VideoConfig, Layer, Sequence, TextElement, ImageElement, TTSElement, VideoElement } from './types';
// import { calculateSequenceDuration } from './sequenceUtils';
import { delayRender, continueRender } from 'remotion';
import TTSComponent from './MediaComponents/TTSComponent'; // Import the TTSComponent
import CaptionsComponent from './MediaComponents/CaptionsComponent'; // Import the CaptionsComponent
import { calculateVideoTimings } from './timingUtils';

type CompositionProps = {
  readonly config: VideoConfig;
  readonly videoWidth: number;
  readonly videoHeight: number;
};

const VideoComposition: React.FC<CompositionProps> = ({ config, videoWidth, videoHeight }) => {
  const { captions } = config;
  const [handle] = useState(() => delayRender());
  const [imagesLoaded, setImagesLoaded] = useState(false);


  // Calculate all timings at once
  const processedConfig = calculateVideoTimings(config);

  useEffect(() => {
    const imageElements = processedConfig.layers.flatMap(layer =>
      layer.sequences.flatMap(sequence =>
        sequence.elements.filter(element => 
          element.type === 'Image' && element.src && element.src.trim() !== ''
        ) as ImageElement[]
      )
    );
  
    // If no valid images, continue render immediately
    if (imageElements.length === 0) {
      setImagesLoaded(true);
      continueRender(handle);
      return;
    }
  
    const imagePromises = imageElements.map(imageElement => {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = imageElement.src;
        img.onload = () => resolve();
        img.onerror = () => {
          console.error(`Error loading image: ${imageElement.src}`);
          resolve(); // Resolve even if there's an error
        };
      });
    });
  
    Promise.all(imagePromises)
      .then(() => {
        setImagesLoaded(true);
        continueRender(handle);
      })
      .catch(error => {
        console.error('Unexpected error loading images', error);
        setImagesLoaded(true); // Set to true even on error to allow rendering
        continueRender(handle);
      });
  }, [processedConfig, handle]);
  

  if (!imagesLoaded) {
    return null;
  }

  // Simply sort layers by depth without modifying the timing logic
  const adjustedLayers = processedConfig.layers.sort((a, b) => a.depth - b.depth);

  return (
    <div
      style={{
        position: 'relative',
        width: videoWidth,
        height: videoHeight,
        overflow: 'hidden',
        backgroundColor: 'black',
      }}
    >
      {/* Existing layers rendering */}
      {adjustedLayers.map((layer: Layer) =>
        <div
          key={layer.id}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: videoWidth,
            height: videoHeight,
            zIndex: layer.depth // Ensure proper stacking order
          }}
        >
          {layer.sequences.map((sequence: Sequence) =>
            sequence.elements.map((element, index) => {
              switch (element.type) {
                case 'Text': {
                  const textElement = element as TextElement;
                  return (
                    <TextComponent
                      key={index}
                      id={textElement.id}
                      text={textElement.text}
                      startTime={textElement.startTime}
                      durationMethod={textElement.durationMethod}
                      actualStartTime={textElement.actualStartTime}
                      actualDuration={textElement.actualDuration}
                      x={textElement.x}
                      y={textElement.y}
                      boxWidth={textElement.boxWidth}
                      boxHeight={textElement.boxHeight}
                      horizontalAlign={textElement.horizontalAlign}
                      verticalAlign={textElement.verticalAlign}
                      fontSize={textElement.fontSize}
                      wrap={textElement.wrap}
                      scaleToFit={textElement.scaleToFit}
                      animateIn={textElement.animateIn}
                      animateOut={textElement.animateOut}
                      videoWidth={videoWidth}
                      videoHeight={videoHeight}
                      depth={textElement.depth}
                      styles={textElement.styles}
                    />
                  );
                }
                case 'Image': {
                  const imageElement = element as ImageElement;
                  // Only render if src exists and is not empty
                  if (!imageElement.src || imageElement.src.trim() === '') {
                    console.warn(`Image element ${imageElement.id} has no source, skipping render`);
                    return null;
                  }
                  return (
                    <ImageComponent
                      key={index}
                      id={imageElement.id}
                      src={imageElement.src}
                      startTime={imageElement.startTime}
                      durationMethod={imageElement.durationMethod}
                      actualDuration={imageElement.actualDuration}
                      actualStartTime={imageElement.actualStartTime}
                      x={imageElement.x}
                      y={imageElement.y}
                      boxWidth={imageElement.boxWidth}
                      boxHeight={imageElement.boxHeight}
                      objectFit={imageElement.objectFit}
                      border={imageElement.border}
                      animateIn={imageElement.animateIn}
                      animateOut={imageElement.animateOut}
                      videoWidth={videoWidth}
                      videoHeight={videoHeight}
                      depth={imageElement.depth}
                      borderRadius={imageElement.borderRadius}
                    />
                  );
                }
                case 'TTS': {
                  const ttsElement = element as TTSElement;
                  return (
                    <TTSComponent
                      key={index}
                      id={ttsElement.id}
                      // text={ttsElement.text}
                      src={ttsElement.src}
                      startTime={ttsElement.startTime}
                      durationMethod={ttsElement.durationMethod}
                      actualStartTime={ttsElement.actualStartTime}
                      actualDuration={ttsElement.actualDuration}
                      // videoWidth={videoWidth}
                      // videoHeight={videoHeight}
                      // depth={ttsElement.depth}
                      />
                  );
                }
                case 'Video': {
                  const videoElement = element as VideoElement;
                  return (
                    <VideoComponent
                      key={index}
                      id={videoElement.id}
                      src={videoElement.src}
                      startTime={videoElement.startTime}
                      durationMethod={videoElement.durationMethod}
                      actualStartTime={videoElement.actualStartTime}
                      actualDuration={videoElement.actualDuration}
                      x={videoElement.x}
                      y={videoElement.y}
                      boxWidth={videoElement.boxWidth}
                      boxHeight={videoElement.boxHeight}
                      objectFit={videoElement.objectFit}
                      volume={videoElement.volume}
                      startFrom={videoElement.startFrom}
                      border={videoElement.border}
                      loop={videoElement.loop}
                      animateIn={videoElement.animateIn}
                      animateOut={videoElement.animateOut}
                      videoWidth={videoWidth}
                      videoHeight={videoHeight}
                      depth={videoElement.depth}
                      adjustSpeed={videoElement.adjustSpeed}
                      transparencyConfig={videoElement.transparencyConfig || {
                        enabled: false,
                        targetColor: [255, 255, 255],
                        threshold: 240,
                        variance: 15
                      }}
                    />
                  );
                }
                default:
                  return null;
                
              }
            })
          )}
        </div>
      )}
      {captions && captions.enabled && (
        <CaptionsComponent
          captions={captions.captions}
          captionsStyle={captions.captionsStyle}
        />
      )}
    </div>
  );
};

export default VideoComposition;
