import React from 'react';
import { useCurrentFrame, useVideoConfig, spring, interpolate } from 'remotion';
import { CaptionsStyleConfig, WordData } from '../types';

interface CaptionsComponentProps {
  readonly captions: WordData[];
  readonly position?: 'top' | 'bottom' | 'center'; // Added 'center' as an option
  readonly captionsStyle: CaptionsStyleConfig;
  readonly batchTime?: number; // Timeframe in seconds to batch words together
}

const defaultCaptionsStyle: CaptionsStyleConfig = {
  font: 'Komika Axis, sans-serif',
  fontSize: '90px',
  color: 'white',
  textShadow: '0px 0px 16px #000000',
  webkitTextStroke: '4px black',
  textAlign: 'center',
};

const CaptionsComponent: React.FC<CaptionsComponentProps> = ({
  captions,
  position = 'center', // Default to 'center'
  captionsStyle = defaultCaptionsStyle, // Use the default style object
  batchTime = 1, // Default batch time is 1 second
}) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  // Calculate current time in seconds
  const currentTime = frame / fps;

  // Batch words together within the specified batch time and separate if there's a gap
  const batches = captions.reduce<WordData[][]>((acc, word) => {
    const lastBatch = acc[acc.length - 1];
    const previousWord = lastBatch ? lastBatch[lastBatch.length - 1] : null;

    // Check if there's a gap between the previous word's end and the current word's start
    const gapExists = previousWord ? word.start - previousWord.end > 0 : false;

    if (!lastBatch || gapExists || word.start - lastBatch[0].start > batchTime) {
      // Start a new batch if there's a gap or the batch time is exceeded
      acc.push([word]);
    } else {
      // Add to the existing batch
      lastBatch.push(word);
    }

    return acc;
  }, []);

  // Determine the position of the captions
  const positionStyle: React.CSSProperties = position === 'top'
    ? { top: '10%', transform: 'translateX(-50%)' }
    : position === 'bottom'
    ? { bottom: '10%', transform: 'translateX(-50%)' }
    : { top: '50%', transform: 'translate(-50%, -50%)' }; // Center position


    // Handle stacked shadows
    const textShadow = Array.isArray(captionsStyle.textShadow)
    ? captionsStyle.textShadow.join(', ')
    : captionsStyle.textShadow;
    
  return (
    <>
      {batches.map((batch, batchIndex) => {
        const batchStartTime = batch[0].start * fps;
        const batchEndTime = batch[batch.length - 1].end * fps;

        // Calculate the spring animation for each batch
        const enterProgress = spring({
          frame: frame - batchStartTime, // Adjust the frame to start the spring animation for each batch
          fps,
          config: {
            damping: 200,
          },
          durationInFrames: 5, // Adjust duration for the spring effect
        });

        // Only render the batch if the current frame is within the batch's time range
        if (frame >= batchStartTime && frame <= batchEndTime) {
          return (
            <div
              key={batchIndex}
              style={{
                position: 'absolute',
                left: '50%',
                textAlign: captionsStyle.textAlign,
                fontSize: captionsStyle.fontSize, // Specify font size separately
                padding: '5px 10px',
                borderRadius: '5px',
                width: '80%',
                height: 'auto',
                fontWeight: 'bold',
                fontFamily: captionsStyle?.font || 'Komika Axis, sans-serif',
                zIndex: 1000, // Ensure it appears on top of other elements
                WebkitTextStroke: captionsStyle.webkitTextStroke, // Add stroke for better visibility
                textShadow,
                ...positionStyle,
                transform: `${positionStyle.transform} scale(${interpolate(enterProgress, [0, 1], [0.8, 1])}) translateY(${interpolate(enterProgress, [0, 1], [50, 0])}px)`, // Apply the spring animation
              }}
            >
              {batch.map((word, index) => (
                <span
                  key={index}
                  style={currentTime >= word.start && currentTime <= word.end ? word.highlightStyle : { color: captionsStyle.color }} // Apply highlight style to the current word, default to white if not highlighted
                >
                  {word.word}{' '}
                </span>
              ))}
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default CaptionsComponent;
