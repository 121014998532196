// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, /* connectAuthEmulator */} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions, /* connectFunctionsEmulator */} from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB6UgxpLxTqbK6aulvKrtxP-eMfpIFcaso",
  authDomain: "tiktok-video-generator-app.firebaseapp.com",
  projectId: "tiktok-video-generator-app",
  storageBucket: "tiktok-video-generator-app.appspot.com",
  messagingSenderId: "704815623560",
  appId: "1:704815623560:web:4b72a5b63ef3dd7a281e3d",
  measurementId: "G-G4Q518KVE6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app, 'us-central1');

/* if (process.env.REACT_APP_NODE_ENV === 'development') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFunctionsEmulator(functions, 'localhost', 5001);
} */

export { app, auth, firestore, functions };