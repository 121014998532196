import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Player } from '@remotion/player';
import VideoComposition from '../Composition';
import { calculateTotalDuration, calculateVideoTimings } from '../timingUtils';

const defaultConfig = {
  videoWidth: 1080, 
  videoHeight: 1920, 
  frameRate: 30, 
  layers: []
};

const RemotionPlayer = forwardRef((props, ref) => {
  const [jsonData, setJsonData] = useState(defaultConfig);
  const [scale, setScale] = useState(1);
  const compositionWidth = 1080;
  const compositionHeight = 1920;
  const playerRef = useRef(null);

  useEffect(() => {
    window.jsonChunks = [];
    
    window.processJsonChunks = function() {
      const fullJson = window.jsonChunks.join('');
      window.jsonChunks = [];
      try {
        if (!fullJson || fullJson.trim() === '') {
          console.error('Empty JSON string received');
          return false;
        }
        const data = JSON.parse(fullJson);
        const processedData = calculateVideoTimings(data);
        setJsonData(data);
        window.onVariableElementsOrder.postMessage(
          JSON.stringify(processedData.orderOfVariableElements || [])
        );
        console.log('JSON data processed successfully');
        return true;
      } catch (error) {
        console.error('Error processing JSON chunks:', error, '\nReceived JSON:', fullJson);
        return false;
      }
    };

    window.injectJSONData = (json) => {
      try {
        if (!json || json.trim() === '') {
          console.error('Empty JSON string received');
          return;
        }
        const data = JSON.parse(json);
        const processedData = calculateVideoTimings(data);
        setJsonData(data);
        window.onVariableElementsOrder.postMessage(
          JSON.stringify(processedData.orderOfVariableElements || [])
        );
      } catch (error) {
        console.error('Error parsing JSON:', error, '\nReceived JSON:', json);
      }
    };

    return () => {
      delete window.jsonChunks;
      delete window.processJsonChunks;
      delete window.injectJSONData;
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const widthScale = window.innerWidth / compositionWidth;
      const heightScale = window.innerHeight / compositionHeight;
      setScale(Math.min(widthScale, heightScale));
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [compositionWidth, compositionHeight]);

  const hasLayersAndSequences = jsonData.layers && jsonData.layers.length > 0;

  const totalDurationInFrames = useMemo(() => {
    if (!hasLayersAndSequences) {
      return 150; // 5 seconds at 30fps
    }
    return calculateTotalDuration(jsonData);
  }, [jsonData, hasLayersAndSequences]);

  useImperativeHandle(ref, () => ({
    play: () => playerRef.current?.play(),
    pause: () => playerRef.current?.pause(),
    seekTo: (frame) => playerRef.current?.seekTo(frame),
    getCurrentFrame: () => playerRef.current?.getCurrentFrame() || 0,
    getDuration: () => totalDurationInFrames,
    isPlaying: () => playerRef.current?.isPlaying() || false,
  }));

  useEffect(() => {
    window.player = {
      play: () => {
        console.log('Play function called');
        playerRef.current?.play();
      },
      pause: () => {
        console.log('Pause function called');
        playerRef.current?.pause();
      },
      seekTo: (frame) => {
        console.log(`Seek to frame: ${frame}`);
        playerRef.current?.seekTo(frame);
      },
      getCurrentFrame: () => {
        const frame = playerRef.current?.getCurrentFrame() || 0;
        console.log(`Current frame: ${frame}`);
        return frame;
      },
      getDuration: () => {
        console.log(`Total duration: ${totalDurationInFrames}`);
        return totalDurationInFrames;
      },
      isPlaying: () => {
        const playing = playerRef.current?.isPlaying() || false;
        console.log(`Is playing: ${playing}`);
        return playing;
      },
      getVariableElementsOrder: () => {
        console.log('Variable elements order:', jsonData.orderOfVariableElements);
        return jsonData.orderOfVariableElements || [];
      },
    };
    console.log('window.player object set up');
  }, [totalDurationInFrames, jsonData]);

  if (!hasLayersAndSequences) {
    return <div>Loading...</div>;
  }

  return (
    <div className="player-container">
      <div
        className="player-wrapper"
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'center',
          width: compositionWidth,
          height: compositionHeight,
        }}
      >
        <Player
          ref={playerRef}
          component={VideoComposition}
          inputProps={{
            config: jsonData,
            videoWidth: compositionWidth,
            videoHeight: compositionHeight,
          }}
          durationInFrames={totalDurationInFrames}
          compositionWidth={compositionWidth}
          compositionHeight={compositionHeight}
          fps={jsonData.frameRate}
          style={{ width: `${compositionWidth}px` }}
        />
      </div>
    </div>
  );
});

export default RemotionPlayer;
