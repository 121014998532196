import React from 'react';

const ComingSoon: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-slate-700">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-emerald-400 mb-4">QuixVid</h1>
        <p className="text-white text-xl">Coming soon!</p>
      </div>
    </div>
  );
};

export default ComingSoon; 