import React, { useCallback, useRef } from 'react';
import { useCurrentFrame, Sequence, OffthreadVideo, Loop, useVideoConfig, AbsoluteFill } from 'remotion';
import { StartTimeMethod, DurationMethod } from '../types';
import { calculateScalingFactor } from '../scalingUtils';
import { Animation, getAnimationStyles } from '../animationUtils';
import { getVideoMetadata } from "@remotion/media-utils";
// import { prefetch } from 'remotion';

interface VideoComponentProps {
  readonly id: string;
  readonly src: string;
  readonly startTime: StartTimeMethod;
  readonly durationMethod: DurationMethod;
  readonly actualStartTime: number;
  readonly actualDuration: number;
  readonly x: number;
  readonly y: number;
  readonly boxWidth: number;
  readonly boxHeight: number;
  readonly objectFit: string;
  readonly volume?: number;
  readonly startFrom?: number;
  readonly border?: string;
  readonly animateIn?: Animation[];
  readonly animateOut?: Animation[];
  readonly videoWidth: number;
  readonly videoHeight: number;
  readonly depth: number;
  // eslint-disable-next-line react/boolean-prop-naming
  readonly loop?: boolean;
  readonly premountFrames?: number;
  readonly transparencyConfig?: {
    targetColor: [number, number, number]; // RGB values
    threshold: number;     // How close to the target color (0-255)
    variance: number;      // Maximum difference between RGB channels
    enabled: boolean;      // Whether to apply transparency
  };
  // eslint-disable-next-line react/boolean-prop-naming
  readonly adjustSpeed?: boolean;
}

const VideoComponent: React.FC<VideoComponentProps> = ({
  id,
  src,
  startTime,
  durationMethod,
  actualStartTime,
  actualDuration,
  x,
  y,
  boxWidth,
  boxHeight,
  objectFit,
  volume = 1,
  startFrom = 0,
  border,
  animateIn = [],
  animateOut = [],
  videoWidth,
  videoHeight,
  depth,
  loop = false,
  premountFrames = 10,
  transparencyConfig = {
    targetColor: [255, 255, 255], // Default white
    threshold: 240,               // Default threshold
    variance: 15,                 // Default variance
    enabled: false,               // Disabled by default
  },
  adjustSpeed = false,
}) => {
  id;
  startTime;
  durationMethod;
  loop;
  volume;
  premountFrames;
  const frame = useCurrentFrame();
  const canvas = useRef<HTMLCanvasElement>(null);
  const [videoMetadata, setVideoMetadata] = React.useState<{width: number, height: number} | null>(null);

  React.useEffect(() => {
    if (!src || src.trim() === '') {
      return;
    }
    
    getVideoMetadata(src)
      .then(({ width, height }) => {
        setVideoMetadata({ width, height });
      })
      .catch(error => {
        console.warn(`Error loading video metadata for ${src}:`, error);
      });
  }, [src]);

  React.useEffect(() => {
    if (!src || src.trim() === '') {
      return;
    }
    
    getVideoMetadata(src)
      .then(({ durationInSeconds }) => {
        setVideoDuration(durationInSeconds);
      })
      .catch(error => {
        console.warn(`Error loading video duration for ${src}:`, error);
      });
  }, [src]);

  const { scaleX, scaleY } = calculateScalingFactor(videoWidth, videoHeight);

  const startFrame = actualStartTime;
  const duration = actualDuration;

  const { fps } = useVideoConfig();
  const [videoDuration, setVideoDuration] = React.useState<number | null>(null);

  /* React.useEffect(() => {
    const { free, waitUntilDone } = prefetch(src, {
      method: 'blob-url',
      contentType: 'video/mp4',
      onProgress: (progress) => {
        if (progress.totalBytes === null) {
          console.log('Loaded bytes:', progress.loadedBytes);
          return;
        }
        
        const percentage = Math.round(
          (progress.loadedBytes / progress.totalBytes) * 100
        );
        console.log('Loading progress:', percentage + '%');
      }
    });

    waitUntilDone()
      .then((url) => {
        console.log(`Video ${src} has finished loading, URL: ${url}`);
        continueRender(handle);
      })
      .catch((err) => {
        console.error(`Error loading video:`, err);
        continueRender(handle);
      });

    return () => {
      free();
    };
  }, [src, handle]); */

  // Process a frame
  
  

  const scaledBoxWidth = boxWidth * scaleX;
  const scaledBoxHeight = boxHeight * scaleY;

  const relativeFrame = frame - startFrame;
  const totalFrames = duration;

  let animationStyle = {};

  if (animateIn.length > 0 && relativeFrame < animateIn[0].duration) {
    animationStyle = getAnimationStyles(relativeFrame, animateIn);
  } else if (animateOut.length > 0 && relativeFrame >= totalFrames - animateOut[0].duration) {
    const outFrame = relativeFrame - (totalFrames - animateOut[0].duration);
    animationStyle = getAnimationStyles(outFrame, animateOut, true);
  }

  const containerStyle = {
    overflow: 'hidden',
    position: 'absolute',
    left: x * scaleX,
    top: y * scaleY,
    width: scaledBoxWidth,
    height: scaledBoxHeight,
    zIndex: depth,
    ...animationStyle,
  } as React.CSSProperties;

  const videoStyle = {
    width: '100%',
    height: '100%',
    objectFit,
    boxShadow: border,
  } as React.CSSProperties;


  const onVideoFrame = useCallback(
    (frame: CanvasImageSource) => {
      if (!canvas.current || !videoMetadata) return;
      const context = canvas.current.getContext('2d');
      if (!context) return;

      // Get dimensions safely based on the frame type
      const frameWidth = (frame instanceof HTMLVideoElement) ? frame.videoWidth :
                        (frame instanceof HTMLImageElement) ? frame.naturalWidth :
                        (frame instanceof HTMLCanvasElement) ? frame.width :
                        videoMetadata.width;
      
      const frameHeight = (frame instanceof HTMLVideoElement) ? frame.videoHeight :
                         (frame instanceof HTMLImageElement) ? frame.naturalHeight :
                         (frame instanceof HTMLCanvasElement) ? frame.height :
                         videoMetadata.height;

      // Calculate dimensions and positioning
      let sx = 0;
      let sy = 0;
      let sWidth = frameWidth;
      let sHeight = frameHeight;
      let dx = 0;
      let dy = 0;
      let dWidth = scaledBoxWidth;
      let dHeight = scaledBoxHeight;

      if (objectFit === 'contain') {
        const videoRatio = Number(frameWidth) / Number(frameHeight);
        const boxRatio = Number(scaledBoxWidth) / Number(scaledBoxHeight);

        if (videoRatio > boxRatio) {
          // Video is wider - adjust height
          dWidth = scaledBoxWidth;
          dHeight = dWidth / videoRatio;
          dy = (scaledBoxHeight - dHeight) / 2;
        } else {
          // Video is taller - adjust width
          dHeight = scaledBoxHeight;
          dWidth = dHeight * videoRatio;
          dx = (scaledBoxWidth - dWidth) / 2;
        }
      } else if (objectFit === 'cover') {
        const videoRatio = Number(frameWidth) / Number(frameHeight);
        const boxRatio = Number(scaledBoxWidth) / Number(scaledBoxHeight);

        if (videoRatio > boxRatio) {
          // Video is wider - crop the sides
          const newSWidth = Number(sHeight) * boxRatio;
          sx = (Number(frameWidth) - newSWidth) / 2;
          sWidth = newSWidth;
        } else {
          // Video is taller - crop the top/bottom
          const newSHeight = Number(sWidth) / boxRatio;
          sy = (Number(frameHeight) - newSHeight) / 2;
          sHeight = newSHeight;
        }
      }

      // Clear the canvas before drawing
      context.clearRect(0, 0, scaledBoxWidth, scaledBoxHeight);
      
      // Draw with the calculated dimensions
      context.drawImage(frame, 
        sx, sy, sWidth, sHeight,  // source rectangle
        dx, dy, dWidth, dHeight   // destination rectangle
      );
      
      if (!transparencyConfig.enabled) return;

      const imageFrame = context.getImageData(0, 0, scaledBoxWidth, scaledBoxHeight);
      const [targetR, targetG, targetB] = transparencyConfig.targetColor;
      
      for (let i = 0; i < imageFrame.data.length; i += 4) {
        const red = imageFrame.data[i + 0];
        const green = imageFrame.data[i + 1];
        const blue = imageFrame.data[i + 2];
        
        // Check if pixel color is within threshold of target color
        const redDiff = Math.abs(red - targetR);
        const greenDiff = Math.abs(green - targetG);
        const blueDiff = Math.abs(blue - targetB);
        
        if (
          redDiff <= transparencyConfig.threshold &&
          greenDiff <= transparencyConfig.threshold &&
          blueDiff <= transparencyConfig.threshold &&
          Math.abs(red - green) <= transparencyConfig.variance &&
          Math.abs(green - blue) <= transparencyConfig.variance &&
          Math.abs(blue - red) <= transparencyConfig.variance
        ) {
          imageFrame.data[i + 3] = 0; // Set alpha to 0
        }
      }
      context.putImageData(imageFrame, 0, 0);
    },
    [scaledBoxHeight, scaledBoxWidth, transparencyConfig, videoMetadata, objectFit],
  );
 

  // Calculate playback rate directly with bounds
  const playbackRate = (videoDuration && adjustSpeed)
    ? Math.min(16, Math.max(0.0625, videoDuration / (actualDuration / fps)))
    : 1;

  const videoContent = (
    <AbsoluteFill>
      <OffthreadVideo
        pauseWhenBuffering
        transparent
        muted={volume === 0}
        src={src}
        volume={volume}
        delayRenderRetries={3}
        style={{
          ...videoStyle,
          pointerEvents: 'none',
          position: 'absolute',
          maxWidth: '100%',
          maxHeight: '100%',
          opacity: 0
        }}
        startFrom={startFrom}
        playbackRate={playbackRate}
        onVideoFrame={onVideoFrame}
        onError={(e) => {
          throw new Error(`Video error: ${e}`);
        }}
      />
      <canvas 
        ref={canvas} 
        width={scaledBoxWidth} 
        height={scaledBoxHeight}
        style={{
          ...videoStyle,
          pointerEvents: 'none',
          position: 'absolute',
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    </AbsoluteFill>
  );

  // Early return if src is empty
  if (!src || src.trim() === '') {
    console.log('Waiting for valid video source...');
    return null;
  }

  return (
    <Sequence from={startFrame} durationInFrames={duration} premountFor={premountFrames}>
      <div style={containerStyle}>
        {loop && videoDuration ? (
          <Loop durationInFrames={Math.floor(fps * videoDuration)}>
            {videoContent}
          </Loop>
        ) : (
          videoContent
        )}
      </div>
    </Sequence>
  );
};

export default VideoComponent; 