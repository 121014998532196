import { VideoConfig, Element, Sequence, Layer } from './types';

interface TimingCache {
  [elementId: string]: {
    actualStartTime: number;
    actualDuration: number;
  };
}

function calculateElementTiming(
  element: Element,
  videoConfig: VideoConfig,
  cache: TimingCache = {}
): { actualStartTime: number; actualDuration: number } {
  // Check cache first
  if (cache[element.id]) {
    return cache[element.id];
  }

  // Calculate start time
  const startTime = calculateStartTime(element, videoConfig, cache);
  
  // Calculate duration
  const duration = calculateDuration(element, videoConfig, cache, startTime);

  // Cache the results
  cache[element.id] = {
    actualStartTime: startTime,
    actualDuration: duration
  };

  return cache[element.id];
}

function calculateStartTime(
  element: Element,
  videoConfig: VideoConfig,
  cache: TimingCache
): number {
  const { type } = element.startTime;

  if (type === 'startAt') {
    // Add sequence offset
    return element.startTime.time + getSequenceOffset(element, videoConfig, cache);
  }

  if (type === 'startAfterComponent') {
    // Find the dependent element
    const dependentElement = findElementById(element.startTime.componentId, videoConfig);
    if (!dependentElement) {
      throw new Error(`Dependent element ${element.startTime.componentId} not found`);
    }

    // Calculate the dependent element's timing first
    const dependentTiming = calculateElementTiming(dependentElement, videoConfig, cache);
    return dependentTiming.actualStartTime + dependentTiming.actualDuration + element.startTime.offset;
  }

  return 0;
}

function calculateDuration(
  element: Element,
  videoConfig: VideoConfig,
  cache: TimingCache,
  startTime: number
): number {
  // If element already has an actualDuration set, use that instead
  if (element.actualDuration !== undefined && element.actualDuration > 0) {
    return element.actualDuration;
  }

  const { type } = element.durationMethod;

  if (type === 'setDuration') {
    return element.durationMethod.duration;
  }

  if (type === 'endWith') {
    const { componentId, offset } = element.durationMethod;
    const dependentElement = findElementById(componentId, videoConfig);
    if (!dependentElement) {
      throw new Error(`Dependent element ${componentId} not found`);
    }

    const dependentTiming = calculateElementTiming(dependentElement, videoConfig, cache);
    return (dependentTiming.actualStartTime + dependentTiming.actualDuration + offset) - startTime;
  }

  if (type === 'endWithVideo') {
    // Calculate the total video duration by finding the latest ending element
    // that doesn't have endWithVideo duration method
    let maxEndTime = 0;
    videoConfig.layers.forEach(layer => {
      layer.sequences.forEach(sequence => {
        sequence.elements.forEach(el => {
          // Skip elements with endWithVideo to avoid circular dependency
          if (el.id === element.id || el.durationMethod.type === 'endWithVideo') return;
          
          const timing = calculateElementTiming(el, videoConfig, cache);
          const endTime = timing.actualStartTime + timing.actualDuration;
          maxEndTime = Math.max(maxEndTime, endTime);
        });
      });
    });
    
    // If no other elements found, provide a fallback duration
    if (maxEndTime === 0) {
      throw new Error('Cannot calculate endWithVideo duration: No elements with concrete duration found');
    }
    
    // Return the duration needed to reach the video end
    return maxEndTime - startTime;
  }

  return 0;
}

function getSequenceOffset(
  element: Element,
  videoConfig: VideoConfig,
  cache: TimingCache
): number {
  const { sequence, layer } = findSequenceAndLayer(element, videoConfig);
  if (!sequence || !layer) return 0;

  const { sequences } = layer;
  const currentSequenceIndex = sequences.findIndex(seq => seq.id === sequence.id);
  
  if (currentSequenceIndex === 0) return 0;

  // Instead of processing all previous sequences independently,
  // we'll calculate where the current sequence should start based on
  // when the previous sequence ends
  const previousSequence = sequences[currentSequenceIndex - 1];
  const previousSequenceDuration = calculateSequenceDuration(previousSequence, videoConfig, cache);
  
  // If this is sequence 2, we just return sequence 1's duration
  if (currentSequenceIndex === 1) {
    return previousSequenceDuration;
  }

  // For sequences 3+, we need to get the start time of the previous sequence
  // and add its duration
  const previousSequenceElement = previousSequence.elements[0];
  const previousSequenceTiming = calculateElementTiming(previousSequenceElement, videoConfig, cache);
  
  return previousSequenceTiming.actualStartTime + previousSequenceDuration;
}

function calculateSequenceDuration(
  sequence: Sequence,
  videoConfig: VideoConfig,
  cache: TimingCache
): number {
  let maxEndTime = 0;
  
  sequence.elements.forEach(element => {
    const timing = calculateElementTiming(element, videoConfig, cache);
    const endTime = timing.actualStartTime + timing.actualDuration;
    maxEndTime = Math.max(maxEndTime, endTime);
  });

  // Get the earliest start time in the sequence
  const minStartTime = Math.min(
    ...sequence.elements.map(element => {
      const timing = calculateElementTiming(element, videoConfig, cache);
      return timing.actualStartTime;
    })
  );

  // Return the actual duration (end - start)
  return maxEndTime - minStartTime;
}

// Helper functions
function findElementById(id: string, videoConfig: VideoConfig): Element | null {
  for (const layer of videoConfig.layers) {
    for (const sequence of layer.sequences) {
      const element = sequence.elements.find(el => el.id === id);
      if (element) return element;
    }
  }
  return null;
}

function findSequenceAndLayer(element: Element, videoConfig: VideoConfig): { 
  sequence: Sequence | null;
  layer: Layer | null;
} {
  for (const layer of videoConfig.layers) {
    for (const sequence of layer.sequences) {
      if (sequence.elements.some(el => el.id === element.id)) {
        return { sequence, layer };
      }
    }
  }
  return { sequence: null, layer: null };
}

export function calculateVideoTimings(videoConfig: VideoConfig): VideoConfig {
  const cache: TimingCache = {};
  
  // Process all elements
  videoConfig.layers.forEach(layer => {
    layer.sequences.forEach(sequence => {
      sequence.elements.forEach(element => {
        const timing = calculateElementTiming(element, videoConfig, cache);
        element.actualStartTime = timing.actualStartTime;
        element.actualDuration = timing.actualDuration;
      });
    });
  });

  // Find and sort variable elements by their actual start times
  const variableElements = videoConfig.layers
    .flatMap(layer => 
      layer.sequences.flatMap(sequence => 
        sequence.elements.filter(element => element.variable)
      )
    )
    .sort((a, b) => a.actualStartTime - b.actualStartTime);

  // Add the ordered variable element IDs to the config
  videoConfig.orderOfVariableElements = variableElements.map(element => element.id);

  return videoConfig;
}

export function calculateTotalDuration(config: VideoConfig): number {
  // If there are no layers or sequences, return a minimum duration
  if (!config.layers || config.layers.length === 0 || 
      !config.layers.some(layer => layer.sequences && layer.sequences.length > 0)) {
    return 5; // Return 5 seconds as minimum duration
  }

  let maxEndFrame = 0;
  
  // Process all timings first
  const processedConfig = calculateVideoTimings(config);
  
  // Find the latest ending element across all layers and sequences
  processedConfig.layers.forEach(layer => {
    layer.sequences.forEach(sequence => {
      sequence.elements.forEach(element => {
        const endFrame = Math.ceil((element.actualStartTime + element.actualDuration) * config.frameRate);
        maxEndFrame = Math.max(maxEndFrame, endFrame);
      });
    });
  });

  // If no elements were found, return minimum duration
  if (maxEndFrame === 0) {
    return 5; // Return 5 seconds as minimum duration
  }

  // Add a small buffer (e.g., 30 frames) to ensure all animations complete
  return maxEndFrame / config.frameRate;
}
