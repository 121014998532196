import React  from 'react';
import { useCurrentFrame } from 'remotion';
import { StartTimeMethod, DurationMethod, TextStyleConfig } from '../types';
// import { useComponentTimings } from '../ComponentTimingContext';
import { calculateScalingFactor } from '../scalingUtils';
import { Animation, getAnimationStyles } from '../animationUtils';
// import '../fonts.css';


interface TextComponentProps {
  readonly id: string;
  readonly text: string;
  readonly startTime: StartTimeMethod;
  readonly durationMethod: DurationMethod;
  readonly actualStartTime: number;
  readonly actualDuration: number;
  readonly x: number;
  readonly y: number;
  readonly boxWidth: number;
  readonly boxHeight: number;
  readonly horizontalAlign: 'left' | 'center' | 'right';
  readonly verticalAlign: 'top' | 'middle' | 'bottom';
  readonly fontSize: number;
  // eslint-disable-next-line react/boolean-prop-naming
  readonly wrap: boolean;
  // eslint-disable-next-line react/boolean-prop-naming
  readonly scaleToFit: boolean;
  readonly animateIn?: Animation[];
  readonly animateOut?: Animation[];
  readonly videoWidth: number;
  readonly videoHeight: number;
  readonly depth: number;
  readonly styles?: TextStyleConfig;
}

const TextComponent: React.FC<TextComponentProps> = ({
  id,
  text,
  startTime,
  durationMethod,
  actualStartTime,
  actualDuration,
  x,
  y,
  boxWidth,
  boxHeight,
  horizontalAlign,
  verticalAlign,
  fontSize,
  wrap,
  scaleToFit,
  animateIn = [],
  animateOut = [],
  videoWidth,
  videoHeight,
  depth,
  styles = {}
}) => {
  id;
  startTime;
  durationMethod;
  const frame = useCurrentFrame();

  const { scaleX, scaleY } = calculateScalingFactor(videoWidth, videoHeight);

  const startFrame = actualStartTime
  const duration = actualDuration


  if (frame < startFrame || frame >= startFrame + duration) {
    return null;
  }

  const scaledFontSize = fontSize * Math.min(scaleX, scaleY);
  const scaledBoxWidth = boxWidth * scaleX;
  const scaledBoxHeight = boxHeight * scaleY;

  const relativeFrame = frame - startFrame;
  const totalFrames = duration;

  let animationStyle = {};

  if (animateIn.length > 0 && relativeFrame < animateIn[0].duration) {
    animationStyle = getAnimationStyles(relativeFrame, animateIn);
  } else if (animateOut.length > 0 && relativeFrame >= totalFrames - animateOut[0].duration) {
    const outFrame = relativeFrame - (totalFrames - animateOut[0].duration);
    animationStyle = getAnimationStyles(outFrame, animateOut, true);
  }

  const containerStyle: React.CSSProperties = {
    position: 'absolute',
    left: x * scaleX,
    top: y * scaleY,
    width: scaledBoxWidth,
    height: scaledBoxHeight,
    display: 'flex',
    justifyContent: horizontalAlign,
    alignItems: verticalAlign,
    overflow: 'visible',
    zIndex: depth,
    ...animationStyle,
  };

  // Handle stacked shadows
  const textShadow = Array.isArray(styles.textShadow)
    ? styles.textShadow.join(', ')
    : styles.textShadow;

  const textStyle: React.CSSProperties = {
    fontSize: `${scaledFontSize}px`,
    fontFamily: "'Komika Axis', sans-serif",
    whiteSpace: wrap ? 'normal' : 'nowrap',
    overflow: 'visible',
    textOverflow: 'clip',
    width: wrap ? 'auto' : '100%',
    height: wrap ? 'auto' : '100%',
    display: 'flex',
    textAlign: horizontalAlign,
    alignItems: verticalAlign,
    ...styles,
    textShadow // Apply the combined text shadow styles
  };

  const scaleText = () => {
    if (!scaleToFit) return textStyle;

    let fittedFontSize = Math.min(scaledFontSize, fontSize * Math.min(scaleX, scaleY));
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.visibility = 'hidden';
    container.style.width = `${scaledBoxWidth}px`;
    container.style.height = `${scaledBoxHeight}px`;
    container.style.fontSize = `${fittedFontSize}px`;
    container.innerText = text;
    document.body.appendChild(container);

    while (container.scrollWidth > container.clientWidth || container.scrollHeight > container.clientHeight) {
      fittedFontSize -= 1;
      container.style.fontSize = `${fittedFontSize}px`;
    }

    document.body.removeChild(container);

    return { ...textStyle, fontSize: `${fittedFontSize}px` };
  };

  const finalTextStyle = scaleText();

  return (
    <div style={containerStyle}>
      <div style={finalTextStyle}>
        {text}
      </div>
    </div>
  );
};

export default TextComponent;
