import React, {useRef } from 'react';
import { Audio, Sequence } from 'remotion';
import { DurationMethod, StartTimeMethod } from '../types';
// import { calculateStartFrame } from '../startTimes';
// import { useComponentTimings } from '../ComponentTimingContext';
// import { calculateDuration } from '../durationMethods';

interface TTSComponentProps {
  id: string;
  src: string;
  startTime: StartTimeMethod;
  durationMethod: DurationMethod;
  actualStartTime?: number;
  actualDuration?: number;
}

const TTSComponent: React.FC<TTSComponentProps> = ({
  id,
  src,
  startTime,
  durationMethod,
  actualStartTime,
  actualDuration,
}) => {
  id;
  startTime;
  durationMethod;
  actualDuration;
  const startFrame = actualStartTime
  

  const audioRef = useRef<HTMLAudioElement | null>(null);


  if (!src) {
    return null;
  }

  return (
    <Sequence from={startFrame}>
      <Audio
        ref={audioRef}
        src={src}
      />
    </Sequence>
  );
};

export default TTSComponent;
