import React, { createContext, useContext, useState, useEffect } from 'react';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';

// Define the type for the context
interface AuthContextType {
  currentUser: User | null; // The current user or null if not logged in
  loading: boolean; // Indicates if the authentication state is still loading
}

// Create the context with a default value
const AuthContext = createContext<AuthContextType>({ currentUser: null, loading: true });

// Hook to use the context
export const useAuth = () => useContext(AuthContext);

// Provider component to wrap the app
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null); // State for the current user
  const [loading, setLoading] = useState(true); // State for the loading status

  // Effect to listen to authentication state changes
  useEffect(() => {
    // Function to unsubscribe from the listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("Auth state changed:", user); // Log the user state change
      setCurrentUser(user); // Update the current user state
      setLoading(false); // Set loading to false after the state change
    });

    // Cleanup function to unsubscribe from the listener
    return unsubscribe;
  }, []);

  // Value to be passed to the context provider
  const value = {
    currentUser,
    loading
  };

  // Return the provider with the value and children
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};