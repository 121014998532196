import React from 'react';
import { Img, useCurrentFrame } from 'remotion';
import { StartTimeMethod, DurationMethod } from '../types';
import { calculateScalingFactor } from '../scalingUtils';
import { Animation, getAnimationStyles } from '../animationUtils';

interface ImageComponentProps {
  id: string;
  src: string;
  startTime: StartTimeMethod;
  durationMethod: DurationMethod;
  actualStartTime: number;
  actualDuration: number;
  x: number;
  y: number;
  boxWidth: number;
  boxHeight: number;
  objectFit: string;
  border?: string;
  animateIn?: Animation[];
  animateOut?: Animation[];
  videoWidth: number;
  videoHeight: number;
  depth: number;
  borderRadius?: number;
}

const ImageComponent: React.FC<ImageComponentProps> = ({
  id,
  src,
  startTime,
  durationMethod,
  actualStartTime,
  actualDuration,
  x,
  y,
  boxWidth,
  boxHeight,
  objectFit,
  border,
  animateIn = [],
  animateOut = [],
  videoWidth,
  videoHeight,
  depth,
  borderRadius
}) => {
  id;
  startTime;
  durationMethod;


  const frame = useCurrentFrame();

  const { scaleX, scaleY } = calculateScalingFactor(videoWidth, videoHeight);

  const startFrame = actualStartTime
  const duration = actualDuration

  if (frame < startFrame || frame >= startFrame + duration) {
    return null;
  }

  if (!src || src.trim() === '') {
    console.log('Waiting for valid image source...');
    return null;
  }

  const scaledBoxWidth = boxWidth * scaleX;
  const scaledBoxHeight = boxHeight * scaleY;

  const relativeFrame = frame - startFrame;
  const totalFrames = duration;

  let animationStyle = {};

  if (animateIn.length > 0 && relativeFrame < animateIn[0].duration) {
    animationStyle = getAnimationStyles(relativeFrame, animateIn);
  } else if (animateOut.length > 0 && relativeFrame >= totalFrames - animateOut[0].duration) {
    const outFrame = relativeFrame - (totalFrames - animateOut[0].duration);
    animationStyle = getAnimationStyles(outFrame, animateOut, true);
  }

  const containerStyle = {
    overflow: 'none',
    position: 'absolute',
    left: x * scaleX,
    top: y * scaleY,
    width: scaledBoxWidth,
    height: scaledBoxHeight,
    zIndex: depth,
    ...animationStyle,
  } as React.CSSProperties;
  
  const wrapperStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as React.CSSProperties;
  
  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    width: objectFit === 'contain' ? 'auto' : '100%',
    height: objectFit === 'contain' ? 'auto' : '100%',
    objectFit,
    boxShadow: border,
    borderRadius: `${borderRadius}px`,
  } as React.CSSProperties;
  
  return (
    <div style={containerStyle}>
      <div style={wrapperStyle}>
        <Img src={src} style={imageStyle} alt="" />
      </div>
    </div>
  );
  
  
};

export default ImageComponent;